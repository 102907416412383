import React from 'react';
import { Code } from 'react-feather';
import PropTypes from 'prop-types';
import { Button, Input } from '../../../common/elements';
import AdvanceOptionsModal from '../capture/AdvancedOptionsModal.js';

const Inputs = props => {
  const {
    errors,
    formData,
    onChange,
    withLocksAndRange,
    handleAdvanceOptionsLabel,
    advanceOptionsLabel,
    advanceOptionsModal,
    setAdvanceOptionsModal,
    handleSetAdvanceOptions,
  } = props;

  let showLocks = false;

  if (Object.keys(withLocksAndRange || {}).length > 0) {
    showLocks = true;
  }
  const handleCloseModal = () => {
    setAdvanceOptionsModal(false)
  }
  const openAdvanceOptionsModal = (e) => {
    e.preventDefault();
    setAdvanceOptionsModal(true)
  }

  return (
    <div className="sample-capture-action-wrapper" style={{ marginTop: "-12px" }}>
      <div
        className={errors && errors.fiscalYear && errors.fiscalYear.error
          ? 'select-wrapper has-error'
          : 'select-wrapper'}
      >
        <div>
          <label>Year</label>
        </div>
        <select
          name="fiscalYear"
          onChange={e => onChange(e)}
          style={{ width: '100%', ...showLocks && { marginBottom: '12px' } }}
          value={formData.fiscalYear}
        >
          <option disabled value="">Select Year</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
        {showLocks && (
          <div className="lock-wrapper">
            <Button
              onClick={() => withLocksAndRange.fiscalYearLocked.setter(!withLocksAndRange.fiscalYearLocked.value)}
              type={withLocksAndRange.fiscalYearLocked.value ? 'primary' : 'mello'}
            >
              {withLocksAndRange.fiscalYearLocked.value ? 'Locked' : 'Lock'}
            </Button>
          </div>
        )}
      </div>
      <div
        className={errors && errors.lab && errors.lab.error
          ? 'select-wrapper has-error'
          : 'select-wrapper'}
      >
        <div>
          <label>Lab</label>
        </div>
        <select
          name="lab"
          onChange={e => onChange(e)}
          style={{ width: '100%', ...showLocks && { marginBottom: '12px' } }}
          value={formData.lab}
        >
          <option disabled value="">Select lab</option>
          <option value="Amarillo">Amarillo</option>
          <option value="Dodge City">Dodge City</option>
          <option value="Hastings">Hastings</option>
        </select>
        {showLocks && (
          <div className="lock-wrapper">
            <Button
              onClick={() => { withLocksAndRange.labLocked.setter(!withLocksAndRange.labLocked.value); onChange("labLocked") }}
              type={withLocksAndRange.labLocked.value ? 'primary' : 'mello'}
            >
              {withLocksAndRange.labLocked.value ? 'Locked' : 'Lock'}
            </Button>
          </div>
        )}
      </div>
      <div
        className={errors && errors.sampleType && errors.sampleType.error
          ? 'select-wrapper has-error'
          : 'select-wrapper'}
      >
        <div>
          <label>Sample Type</label>
        </div>
        <select
          name="sampleType"
          onChange={e => onChange(e)}
          style={{ width: '100%', ...showLocks && { marginBottom: '12px' } }}
          value={formData.sampleType}
        >
          <option disabled value="">Select sample type</option>
          <option value="Feed">Feed</option>
          <option value="Fertilizer">Fertilizer</option>
          <option value="Plant">Plant</option>
          <option value="Soil">Soil</option>
          <option value="Environmental">Environmental</option>
          <option value="Other">Other</option>
        </select>
        {showLocks && (
          <div className="lock-wrapper">
            <Button
              onClick={() => { withLocksAndRange.sampleTypeLocked.setter(!withLocksAndRange.sampleTypeLocked.value); onChange("sampleTypeLocked") }}
              type={withLocksAndRange.sampleTypeLocked.value ? 'primary' : 'mello'}
            >
              {withLocksAndRange.sampleTypeLocked.value ? 'Locked' : 'Lock'}
            </Button>
          </div>
        )}
      </div>
      <div>
        <div className="sample-capture-lab-number-wrapper" >
          <Input
            error={errors && errors.labNumber && errors.labNumber.error}
            inputType="number"
            label={showLocks && withLocksAndRange.labRange.value ? 'Lab Range Start' : 'Lab Number'}
            name="labNumber"
            onChange={e => onChange(e)}
            value={formData.labNumber}
          />
          {showLocks && withLocksAndRange.labRange.value && (
            <Input
              error={errors && errors.labNumberEnd && errors.labNumberEnd.error}
              inputType="number"
              label="End"
              name="labNumberEnd"
              onChange={e => onChange(e)}
              value={formData.labNumberEnd}
            />
          )}
        </div>
        {showLocks && (
          <div className="lock-wrapper with-input">
            <button
              className="advance-options-button"
              // variant="outline-dark"
              onClick={(e) => { openAdvanceOptionsModal(e); }}
            >
              {advanceOptionsLabel} &nbsp; <Code size={14} style={{ transform: "rotate(90deg)" }} />
            </button>
            <AdvanceOptionsModal
              isOpen={advanceOptionsModal}
              handleCloseModal={handleCloseModal}
              openAdvanceOptionsModal={openAdvanceOptionsModal}
              handleAdvanceOptionsLabel={handleAdvanceOptionsLabel}
              advanceOptionsLabel={advanceOptionsLabel}
              setModalOpen={setAdvanceOptionsModal}
              handleSetAdvanceOptions={handleSetAdvanceOptions}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Inputs;

Inputs.defaultProps = {
  errors: {},
  formData: {},
  onChange: () => { },
  withLocksAndRange: {},
};

Inputs.propTypes = {
  errors: PropTypes.shape({
    lab: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.string,
    }),
    labNumber: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.string,
    }),
    labNumberEnd: PropTypes.shape({
      error: PropTypes.bool,
    }),
    sampleType: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.string,
    }),
  }),
  formData: PropTypes.shape({
    fiscalYear: PropTypes.string,
    lab: PropTypes.string,
    labNumber: PropTypes.string,
    labNumberEnd: PropTypes.string,
    sampleType: PropTypes.string,
  }),
  onChange: PropTypes.func,
  withLocksAndRange: PropTypes.shape({
    autoIncrement: PropTypes.shape({
      setter: PropTypes.func,
      value: PropTypes.bool,
    }),
    labLocked: PropTypes.shape({
      setter: PropTypes.func,
      value: PropTypes.bool,
    }),
    labRange: PropTypes.shape({
      setter: PropTypes.func,
      value: PropTypes.bool,
    }),
    sampleTypeLocked: PropTypes.shape({
      setter: PropTypes.func,
      value: PropTypes.bool,
    }),
  }),
};
