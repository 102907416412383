import React, { useState } from 'react';
import { Button, Input } from '../../../common/elements';
import { Close, CloseCircle } from '../../../common/icons';
import { Card } from '../../../components';
import config from '../../../config';



const { domain } = config;

const initialState = {
  fiscalYear: '2024',
  lab: 'default',
  labNumber: '',
  labNumberEnd: '',
  sampleType: 'default',
};

const Archive = () => {
  const [errors, setErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [toggleRange, setToggleRange] = useState("OFF");


  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'labNumber' || name === 'labNumberEnd') {
      setFormData({
        ...formData,
        [name]: parseInt(value),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }


    delete errors[name];
    setErrors(errors);
  };

  const handleCloseError = () => setShowErrorModal(false);

  const getSamples = async () => {
    const formErrors = {};

    if (formData.lab === 'default') {
      formErrors.lab = { error: true, message: 'Lab is required' };
    }

    if (formData.sampleType === 'default') {
      formErrors.sampleType = { error: true, message: 'Sample Type is required' };
    }

    if (formData.labNumber === '' && formData.labNumber === 0) {
      formErrors.labNumber = { error: true, message: 'Lab Number is required' };
    }

    if ((formData.labNumberEnd !== '' && formData.labNumberEnd !== 0) && parseInt(formData.labNumber) >= parseInt(formData.labNumberEnd)) {
      formErrors.labNumberEnd = { error: true, message: 'Range End must be higher than Range Start' }
    }

    if (Object.keys(formErrors).length > 0) {
      setShowErrorModal(true);
      setErrors(formErrors);
      return;
    }
  };


  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };


  const handleRangeToggle = () => {
    if (toggleRange === "ON") {
      setToggleRange("OFF");
    } else {
      setToggleRange("ON");
    }
  };

  return (
    <>
      <link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

      {showErrorModal && Object.keys(errors).length > 0 && (
        <div className="error">
          <div aria-hidden="true" className="title-wrapper" onClick={() => handleCloseError()}>
            <CloseCircle color="rgb(255,77,79)" lineColor="rgb(255,77,79)" size={24} />
            <div>Error</div>
            <Close color="rgba(0,0,0,.65)" size={18} />
          </div>
          <ul>
            {Object.entries(errors).map(el => (
              el[1].message
              && <li key={el[1].message}>{el[1].message}</li>
            ))}
          </ul>
        </div>
      )}
      <Card>
        <div className="sample-capture-action-wrapper">
          <div
            className={errors && errors.lab && errors.lab.error
              ? 'select-wrapper has-error'
              : 'select-wrapper'}
          >
            <div>
              <label>Lab</label>
            </div>
            <select
              name="lab"
              onChange={e => onChange(e)}
              style={{ width: '100%' }}
              value={formData.lab}
            >
              <option disabled value="default">Select lab</option>
              <option value="Amarillo">Amarillo</option>
              <option value="Dodge City">Dodge City</option>
              <option value="Hastings">Hastings</option>
            </select>
          </div>
          <div
            className={errors && errors.fiscalYear && errors.fiscalYear.error
              ? 'select-wrapper has-error'
              : 'select-wrapper'}
          >
            <div>
              <label>Fiscal Year</label>
            </div>
            <select
              name="fiscalYear"
              onChange={e => onChange(e)}
              style={{ width: '100%' }}
              value={formData.fiscalYear}
            >
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div>
          <div
            className={errors && errors.sampleType && errors.sampleType.error
              ? 'select-wrapper has-error'
              : 'select-wrapper'}
          >
            <div>
              <label>Sample Type</label>
            </div>
            <select
              name="sampleType"
              onChange={e => onChange(e)}
              style={{ width: '100%' }}
              value={formData.sampleType}
            >
              <option disabled value="default">Select sample type</option>
              <option value="Feed">Feed</option>
              <option value="Fertilizer">Fertilizer</option>
              <option value="Plant">Plant</option>
              <option value="Soil">Soil</option>
              <option value="Environmental">Environmental</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            {/* Range */}
            {toggleRange === "ON" &&
              <div className="sample-capture-lab-number-wrapper">
                <Input
                  error={errors && errors.labNumber && errors.labNumber.error}
                  inputType="number"
                  label='Range Start'
                  name="labNumber"
                  onChange={e => onChange(e)}
                  value={formData.labNumber}
                />
                <Input
                  error={errors && errors.labNumberEnd && errors.labNumberEnd.error}
                  inputType="number"
                  label="Range End"
                  name="labNumberEnd"
                  onChange={e => onChange(e)}
                  value={formData.labNumberEnd}
                />
              </div>
            }
            {/* Not Range */}
            {toggleRange === "OFF" &&
              <div className="sample-capture-lab-number-wrapper">
                <Input
                  error={errors && errors.labNumber && errors.labNumber.error}
                  inputType="number"
                  label='Lab Number'
                  name="labNumber"
                  onChange={e => onChange(e)}
                  value={formData.labNumber}
                />
              </div>
            }
          </div>
        </div>
        <div className="archive-search-wrapper">

          <Button className='range-toggle-button' onClick={(handleRangeToggle)}>Range {toggleRange}</Button>
          {(formData.lab === 'default' || formData.sampleType === 'default' || formData.labNumber === '' || (formData.labNumberEnd !== '' && parseInt(formData.labNumber) >= parseInt(formData.labNumberEnd)) || (formData.labNumberEnd !== 0 && parseInt(formData.labNumber) >= parseInt(formData.labNumberEnd))) &&
            <Button onClick={() => getSamples()} type="primary">Search</Button>
          }
          {
            (formData.lab !== 'default' && formData.sampleType !== 'default' && formData.labNumber !== '' && (formData.labNumberEnd !== '' && parseInt(formData.labNumber) < parseInt(formData.labNumberEnd)) && (formData.labNumberEnd !== 0 && parseInt(formData.labNumber) < parseInt(formData.labNumberEnd))) &&
            <Button type="primary" onClick={(e) => { openInNewTab(`${domain}/sample-capture/view/${formData.fiscalYear}/${formData.lab}/${formData.labNumber}/${formData.labNumberEnd}/${formData.sampleType}`) }}>
              Search
            </Button>
          }
          {
            (formData.lab !== 'default' && formData.sampleType !== 'default' && (formData.labNumber !== '' && formData.labNumber !== 0) && (formData.labNumberEnd === '' && formData.labNumberEnd !== 0)) &&
            <Button type="primary" onClick={(e) => { openInNewTab(`${domain}/sample-capture/view/${formData.fiscalYear}/${formData.lab}/${formData.labNumber}/${0}/${formData.sampleType}`) }}>
              Search
            </Button>
          }
        </div>
      </Card>
    </>
  );
};

export default Archive;